import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { Modal } from 'antd';

import AppStore from 'stores/App';

class PitstopModal extends Component {
  componentDidMount() {
    let { id } = this.props;

    if (!id) {
      throw new Error('prop id is needed for PitstopModal');
    }
    if (!AppStore.openModals.has(id)) {
      AppStore.openModals.set(id, false);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.visible !== this.props.visible) {
      this.setState({ visible: this.props.visible });
    }
  };

  confirmOnOk = async () => {
    if (!this.props.confirmOkMessage) {
      await this.onOk();
      return;
    }
    Modal.confirm({
      title: this.props.confirmOkMessage,
      centered: this.props.centered,
      onOk: async () => {
        await this.onOk();
      },
    });
  };
  hide = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
      this.props.clearExtraFields && this.props.clearExtraFields();
    }
    this.props.form && this.props.form.resetFields();
    AppStore.openModals.set(this.props.id, false);
  };
  onOk = async () => {
    try {
      const onOkResult = await this.props.onOk();
      if (onOkResult === false) return;
      this.showSuccessMessage();
      if (!this.props.isManualClose) {
        AppStore.openModals.set(this.props.id, false);
      }
    } catch (e) {
      this.showFailureMessage();
    }
  };
  show = () => {
    AppStore.openModals.set(this.props.id, false);
  };
  showFailureMessage = () => {
    let { failureMessage, notifyMessage } = this.props;
    if (failureMessage) {
      failureMessage = failureMessage || 'Action failed';
      AppStore.addError(failureMessage);
      notifyMessage && AppStore.addNotification(notifyMessage);
    }
  };
  showSuccessMessage = () => {
    let { successMessage } = this.props;
    if (successMessage) {
      successMessage = successMessage || 'Action successful';
      AppStore.addSuccess(successMessage);
    }
  };
  render() {
    const {
      children,
      okText,
      title,
      width,
      id,
      // eslint-disable-next-line no-unused-vars
      onOk,
      // eslint-disable-next-line no-unused-vars
      onCancel,
      ...otherProps
    } = this.props;

    if (_.isNil(id)) {
      return <></>;
    }

    return (
      <Modal
        visible={AppStore.openModals.get(id)}
        title={title}
        width={width}
        okText={okText}
        onCancel={this.hide}
        onOk={this.confirmOnOk}
        closable={this.props.closable}
        {...otherProps}
        centered={this.props.centered}
        afterClose={this.props.afterClose}
        cancelButtonProps={this.props.cancelButtonProps}
      >
        {children}
      </Modal>
    );
  }
}

PitstopModal.propTypes = {
  form: PropTypes.object,
  failureMessage: PropTypes.string,
  notifyMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  confirmOkMessage: PropTypes.string,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  clearExtraFields: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centered: PropTypes.bool,
  afterClose: PropTypes.func,
  isManualClose: PropTypes.bool,
  cancelButtonProps: PropTypes.object,
  closable: PropTypes.bool
};

PitstopModal.defaultProps = {
  isManualClose: false,
  cancelButtonProps: {},
  closable: false
};

export default observer(PitstopModal);