import React from 'react';

const ContactLink = () => (
  <p
    style={{
      marginTop: '2rem',
      cursor: 'pointer',
      color: '#000000',
    }}
  >
    Have Questions?{' '}
    <a
      href="https://pitstopconnect.com/book-a-demo/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ fontWeight: 600, color: '#000000' }}
    >
      Schedule a Call
    </a>
  </p>
);

export default ContactLink;
