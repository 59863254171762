import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import _ from 'lodash';

import { Alert } from 'antd';

import {
  CarStore,
  CurrentUserStore,
  IntegrationStore,
  ShopStore,
} from 'stores';

import { IntegrationPage } from 'containers/Integration';

class HeaderMessage extends Component {
  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.currentShop.id !== -1) {
      await this.loadIntegrationAccount();
    }
  });

  loadIntegrationAccount = async () => {
    await IntegrationStore.getIntegrationAccountByShop(
      ShopStore.currentShop.id
    );
  };

  componentDidMount() {
    this.loadIntegrationAccount();
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    /* if (
       ShopStore.currentShop.id !== -1 &&
       (!IntegrationStore.loaded || !CarStore.loaded)
     ) {
       return <></>;
     }
 
     let { firstName, email } = CurrentUserStore.user;
 
     let displayedName = 'User';
     if (firstName) {
       displayedName = firstName;
     } else {
       if (email) {
         displayedName = email.split('@')[0];
       }
     }
 
     let description = null;
 
     if (ShopStore.currentShop.id === -1) {
       description = (
         <>
           Here's what's new for you today, <strong>{displayedName}</strong>
         </>
       );
     } else {
       // has no real vehicles
       if (CarStore.hasDemoCars) {
         if (_.isEmpty(IntegrationStore.integrationAccount)) {
           // no integration
           description = (
             <>
               Looks like you have not added/connected your vehicles, let's get
               started!
               <IntegrationPage
                 title="Connect your Fleet"
                 integrationRecordTable={false}
                 addIntegrationBtn={true}
               />
             </>
           );
         } else {
           // has integrations
           description = (
             <>
               Thanks for connecting your vehicles with <strong>Pitstop!</strong>
               . We are syncing your fleet and analyzing the maintenance data to
               give you the best maintenance plan.
               <br />
               We have added demo vehicles to your account so you can get a feel
               of the features that exist on the platform.{' '}
               <strong>Let's get started!</strong>
             </>
           );
         }
       }
 
       // has real vehicles
       if (CarStore.hasRealCars) {
         description = (
           <>
             Hello <strong>{displayedName}</strong>, Good to see you back! We are
             constantly analyzing your fleet to come up with the best maintenance
             plan that works for you.
           </>
         );
       }
     }
 
     if (_.isNil(description)) return <></>;
 
     return (
       <Alert
         style={{ margin: '10px 0px', width: 'fit-content' }}
         description={
           <p style={{ fontSize: 16, margin: 'auto' }}>{description}</p>
         }
         type="info"
       />
     );*/


    return null; // This prevents it from rendering anything, this header message is redundant to SampleData Bar.


  }
}

export default observer(HeaderMessage);