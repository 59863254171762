import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class OnboardingPage extends Component {
  handleLoadDemoData = () => {
    // Set a default demo shop ID to avoid undefined errors in downstream API calls.
    const demoShopId = 'demo-shop-123';
    localStorage.setItem('shopId', demoShopId);
    console.log(`Demo data loaded with shopId: ${demoShopId}`);

    // Navigate to the dashboard (adjust the route as needed).
    if (this.props.history && typeof this.props.history.push === 'function') {
      this.props.history.push('/dashboard');
    }
  };

  render() {
    return (
      <Container>
        <Button type="primary" onClick={this.handleLoadDemoData}>
          Load Demo Data
        </Button>
      </Container>
    );
  }
}

export default OnboardingPage;
