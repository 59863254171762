import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { AppStore, IntegrationStore } from 'stores';
import geotabImage from 'v3/assets/geotab.png';
import samsaraImage from 'v3/assets/samsara.png';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  width: 90%;
  max-width: 700px;
  background: #ffffff;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const IntegrationOption = styled.div`
  border: ${({ selected }) => (selected ? '3px solid #02103d' : '1px solid #ddd')};
  border-radius: 10px;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  height: 180px;
  text-align: center;
  box-shadow: ${({ selected }) => (selected ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : 'none')};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 180px; /* Increased size */
    height: auto;
    object-fit: contain;
  }

  p {
    margin: 0;
    font-size: ${({ isOther }) => (isOther ? '18px' : '0')}; /* Hide text for Geotab/Samsara */
    font-weight: bold;
    color: ${({ selected }) => (selected ? '#02103d' : '#000')};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &:hover {
    border: 3px solid #003399;
  }
`;

const DashLine = styled.div`
  opacity: 0.2;
  border-top: 2px dashed #000000;
  margin: 20px 0;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const ConnectButton = styled.button`
  background: ${({ disabled }) => (disabled ? '#c4c4c4' : '#02103d')};
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${({ disabled }) => (disabled ? '#c4c4c4' : '#003399')};
  }
`;

const CancelButton = styled(ConnectButton)`
  background: #ffffff;
  color: #02103d;
  border: 1px solid #02103d;

  &:hover {
    background: #f0f0f0;
  }
`;

class IntegrationPage extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  state = {
    selectedIntegration: null,
  };

  handleIntegrationSelect = (integration) => {
    this.setState({ selectedIntegration: integration });
  };

  handleConnect = () => {
    const { selectedIntegration } = this.state;
    if (!selectedIntegration) {
      AppStore.addWarning('Please select an integration!');
      return;
    }
    IntegrationStore.modalIntegrationForm = `${selectedIntegration}-integration-form`;
    this.props.onClose(); // Close popup after selection
  };

  render() {
    const { onClose } = this.props;
    const { selectedIntegration } = this.state;

    return (
      <Overlay>
        <PopupContainer>
          <Title>Connect Your Fleet</Title>
          <Subtitle>Connect your fleet with Pitstop by logging in with Geotab, Samsara, and many more!</Subtitle>
          <DashLine />
          <Row gutter={[16, 16]} justify="center">
            <Col span={8}>
              <IntegrationOption
                selected={selectedIntegration === 'geotab'}
                onClick={() => this.handleIntegrationSelect('geotab')}
              >
                <img src={geotabImage} alt="Geotab" />
              </IntegrationOption>
            </Col>
            <Col span={8}>
              <IntegrationOption
                selected={selectedIntegration === 'samsara'}
                onClick={() => this.handleIntegrationSelect('samsara')}
              >
                <img src={samsaraImage} alt="Samsara" />
              </IntegrationOption>
            </Col>
            <Col span={8}>
              <IntegrationOption
                selected={selectedIntegration === 'other'}
                isOther
                onClick={() => this.handleIntegrationSelect('other')}
              >
                <p>Other</p>
              </IntegrationOption>
            </Col>
          </Row>
          <DashLine />
          <ButtonRow>
            <ConnectButton
              disabled={!selectedIntegration}
              onClick={this.handleConnect}
            >
              Connect
            </ConnectButton>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
          </ButtonRow>
        </PopupContainer>
      </Overlay>
    );
  }
}

export default withRouter(observer(IntegrationPage));